<pc-overlay
  [config]="{
    title: 'Add Backend provider'
  }"
  (closeClick)="ocppProxy.showAssignBackendProviderOverlay$.next(false)">
  <ng-container body>
    <!-- dialog body -->
    <form [formGroup]="assignBackendProviderForm" style="width: 100%">
      @for (field of fields; track field) {
        <flex-col style="width: 100%">
          <div class="form-input">
            @if (field.type === 'text') {
              <pc-input-textfield [id]="field.inputId" [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-textfield>
            }
            @if (field.type === 'number') {
              <pc-input-number [id]="field.inputId" [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-number>
            }
            @if (field.type === 'select') {
              <pc-input-select [config]="field" [form]="assignBackendProviderForm"></pc-input-select>
            }
          </div>
        </flex-col>
      }

      <!-- content projection: buttons -->
      <pc-button [disabled]="assignBackendProviderForm.invalid" [text]="'OCPP.ASSIGN_BACKEND_PROVIDER' | translate" (btnClick)="submit()"></pc-button>
    </form>
  </ng-container>
</pc-overlay>
