<div class="container">
  <router-outlet></router-outlet>
</div>

@if (ocppProxy.showAddChargePointOverlay$ | async; as chargePoint) {
  <div>
    <app-ocpp-proxy-add-charge-point-modal></app-ocpp-proxy-add-charge-point-modal>
  </div>
}

@if (ocppProxy.showAddBackendProviderOverlay$ | async; as backendProvider) {
  <div>
    <app-ocpp-proxy-add-backend-provider-modal></app-ocpp-proxy-add-backend-provider-modal>
  </div>
}

@if (ocppProxy.showEditBackendProviderOverlay$ | async; as backendProvider) {
  <div>
    <app-ocpp-proxy-edit-backend-provider-modal [backendProvider]="backendProvider"></app-ocpp-proxy-edit-backend-provider-modal>
  </div>
}

@if (ocppProxy.showAssignBackendProviderOverlay$ | async; as chargePoint) {
  <div>
    <app-ocpp-proxy-assign-backend-provider-modal [chargePoint]="chargePoint"></app-ocpp-proxy-assign-backend-provider-modal>
  </div>
}

@if (ocppProxy.showAssignChargePointBackendProviderPermissionOverlay$ | async; as data) {
  <div>
    <app-ocpp-proxy-charge-point-assign-permission-modal
      [chargePoint]="data.chargePoint"
      [backendProvider]="data.backendProvider"></app-ocpp-proxy-charge-point-assign-permission-modal>
  </div>
}

@if (ocppProxy.showChargePointBackendProviderEditOverlay$ | async; as data) {
  <div>
    <app-ocpp-proxy-charge-backend-edit-modal
      [chargePoint]="data.chargePoint"
      [backendProvider]="data.backendProvider"></app-ocpp-proxy-charge-backend-edit-modal>
  </div>
}

@if (ocppProxy.showEditChargePointOverlay$ | async; as data) {
  <div>
    <app-ocpp-proxy-charge-point-edit-modal [chargePoint]="data"></app-ocpp-proxy-charge-point-edit-modal>
  </div>
}
