<pc-overlay
  [config]="{
    title: 'Edit Backend Provider Settings'
  }"
  (closeClick)="ocppProxy.showAddChargePointOverlay$.next(false)">
  <!-- dialog body -->
  <ng-container body>
    <form [formGroup]="addChargePointForm" style="width: 100%">
      @for (field of fields; track field) {
        <flex-col style="width: 100%">
          <div class="form-input">
            @if (field.type === 'text') {
              <pc-input-textfield [id]="field.inputId" [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-textfield>
            }
            @if (field.type === 'number') {
              <pc-input-number [id]="field.inputId" [control]="field.control" [placeholder]="field.placeholder" variant="fit-width"></pc-input-number>
            }
            @if (field.type === 'select') {
              <pc-input-select [config]="field" [form]="addChargePointForm"></pc-input-select>
            }
          </div>
        </flex-col>
      }
    </form>
  </ng-container>
  <ng-container buttons>
    <!-- content projection: buttons -->
    <pc-button [disabled]="addChargePointForm.invalid" size="small" [text]="'OCPP.ADD_CHARGE_POINT' | translate" (btnClick)="submit()"></pc-button>
  </ng-container>
</pc-overlay>
